import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import './style.css';

const Scrollbar = () => {
    return (
        <ul className="smothscroll">
           
           
        </ul>
    );
};

export default Scrollbar;
