import React from 'react'
import Student_Form from './Student_Form'



const Form1 = () => {
  return (
    <div>
        <Student_Form />
    </div>
  )
}

export default Form1